
.header{
  background-color: white;
  padding: 24px;
  position: fixed;
  width: 100%;
  z-index: 10; /* Ensure header is above other content */
}
.container{
  display: none;
}


@media (min-width: 890px) {
 
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: block
  }
  
  
  .navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navContainer h1 {
    font-size: 2rem;
    cursor: pointer;
    font-family: "Onest", sans-serif;
  }
  
  .nav {
    display: flex;
    gap: 2.5rem;
  }
  
  .nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav ul li {
    display: inline;
    font-family: "Onest", sans-serif;
  }

  
  .activeClass {
    font-weight: 700;
  }
  
 
}
