.icons-container {
  display: flex;
  width: 239px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 32px;
}

@media (min-width: 721px) {
  .icons-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 60px;
  }
}
.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
